import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  getToken
} from '@/utils/auth'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "*",
    name: 'login',
    // redirect: "/login",
    meta: { title: '登录' },
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   meta: { title: '登录' },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  // },
  {
    path: '/home',
    name: 'home',
    meta: { title: '首页' },
    // component: HomeView
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
  },
  {
    path: '/addStaff',
    name: 'addStaff',
    meta: { title: '添加员工' },
    component: () => import(/* webpackChunkName: "about" */ '../views/addStaff.vue')
  },
  {
    path: '/addShop',
    name: 'addShop',
    meta: { title: '添加店铺' },
    component: () => import(/* webpackChunkName: "about" */ '../views/addShop.vue')
  },
  {
    path: '/staffList',
    name: 'staffList',
    meta: { title: '员工列表' },
    component: () => import(/* webpackChunkName: "about" */ '../views/staffList.vue')
  },
  {
    path: '/shopDetail',
    name: 'shopDetail',
    meta: { title: '店铺详情' },
    component: () => import(/* webpackChunkName: "about" */ '../views/shopDetail.vue')
  }, {
    path: '/goodDetail',
    name: 'goodDetail',
    meta: { title: '商品详情' },
    component: () => import(/* webpackChunkName: "about" */ '../views/goodDetail.vue')
  }, {
    path: '/addLoss',
    name: 'addLoss',
    meta: { title: '添加损耗' },
    component: () => import(/* webpackChunkName: "about" */ '../views/addLoss.vue')
  }, {
    path: '/addGoods',
    name: 'addGoods',
    meta: { title: '添加商品' },
    component: () => import(/* webpackChunkName: "about" */ '../views/addGoods.vue')
  }, {
    path: '/operationRecords',
    name: 'operationRecords',
    meta: {
      title: '操作记录',
      // keepAlive: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/operationRecords.vue')
  }, {
    path: '/operationRecordsDetail',
    name: 'operationRecordsDetail',
    meta: { title: '操作记录详情' },
    component: () => import(/* webpackChunkName: "about" */ '../views/operationRecordsDetail.vue')
  }, {
    path: '/goodsList',
    name: 'goodsList',
    meta: { title: '商品列表' },
    component: () => import(/* webpackChunkName: "about" */ '../views/goodsList.vue')
  }, {
    path: '/shopList',
    name: 'shopList',
    meta: { title: '店铺列表' },
    component: () => import(/* webpackChunkName: "about" */ '../views/shopList.vue')
  }, {
    path: '/shopStaffList',
    name: 'shopStaffList',
    meta: { title: '店铺员工列表' },
    component: () => import(/* webpackChunkName: "about" */ '../views/shopStaffList.vue')
  }, {
    path: '/my',
    name: 'my',
    meta: { title: '我的' },
    component: () => import(/* webpackChunkName: "about" */ '../views/my.vue')
  }, {
    path: '/editGoods',
    name: 'editGoods',
    meta: { title: '编辑商品' },
    component: () => import(/* webpackChunkName: "about" */ '../views/editGoods.vue')
  }
]

const router = new VueRouter({
  routes,
})
// 全局的路由钩子函数
router.beforeEach(async (to, from, next) => {
  const hasToken = getToken();
  to.meta.title && (document.title = to.meta.title);
  // if (to.path == "/operationRecordsDetail") {
  //   from.meta.keepAlive = true;
  // } else {
  //   from.meta.keepAlive = false;
  // }
  // console.log(to);
  next()
  // if (hasToken) {
  //   next()
  // } else {
  //   next("/login")
  // }
})
export default router
