import Cookies from 'js-cookie'

let TokenKey = 'Admin-Token';

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  // 设置60分钟后过期
  const inFifteenMinutes = new Date(new Date().getTime() + 60 * 60 * 1000 * 24 * 30);
  return Cookies.set(TokenKey, token, { expires: inFifteenMinutes })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}