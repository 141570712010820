import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseurl: "https://img.linlizhenxuan.com/",
    userinfo: null,
    searchKeyword:null
  },
  getters: {
  },
  mutations: {
    updateuserinfo(state, userinfo) {
      state.userinfo = userinfo;
    },
    updatesearchKeyword(state, searchKeyword) {
      state.searchKeyword = searchKeyword;
    },
  },
  actions: {
  },
  modules: {
  }
})
